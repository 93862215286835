.game {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.gameLogo {
  padding-right: 8px;
}

.gameName {
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
}
