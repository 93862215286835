.topBanner {
  font-size: 14px;
  background-color: var(--bg-darker-color);
  border-radius: 5px;
  transition: height 2s ease-out 1s;
  padding: 10px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.topBanner.hidden {
  display: none;
}

.topBannerIcon {
  padding: 2px 5px 0 5px;
  flex-shrink: 0;
}

.topBannerIcon svg {
  width: 20px;
  height: 20px;
}

.topBannerText {
  margin-left: 10px;
  margin-right: 10px;
}

.topBannerButtons {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 480px) {
  .topBannerButtons {
    flex-direction: row;
    padding-top: 0;
    margin-right: -10px;
  }
}
