.otherGamesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otherGames {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 15px 20px;
  background-color: var(--bg-darker-color);
  border: 1px solid var(--text-color);
  border-radius: 10px;
  max-width: 400px;
}

.moreGames {
  font-size: 16px;
  text-align: center;
  padding-bottom: 10px;
}

.gamesList {
  display: flex;
  align-items: center;
}

.verticalGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
}

.gameLogo {
  padding-bottom: 8px;
}

.gameName {
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
}
