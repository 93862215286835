.moreGames {
  font-size: 14px;
  padding-top: 10px;
}

.gamesList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.game {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.gameLogo {
  padding-right: 8px;
}

.gameName {
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
}

.newTag {
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background-color: #22c55e;
  padding: 3px 6px;
  border-radius: 4px;
  margin-left: 4px;
}
